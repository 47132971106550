import {Component, OnInit} from '@angular/core';
import {LazyLoadEvent, MenuItem, MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {ExcelService} from '../services/excel.service';
import moment from 'moment/moment';
import {SecondDandrService} from '../services/rfi/second-dandr.service';

@Component({
	selector: 'second-dandr',
	templateUrl: './second-dandr.component.html',
	styleUrls: ['./second-dandr.component.css']
})
export class SecondDandrComponent implements OnInit {
	columns = [];
	secondDandRs = [];
	private secondDandRExcel = [];
	breadCrumItems: MenuItem[];
	dtSelectedRows;
	isMoving = 'Moving';
	seconddandr;
	fromDate;
	toDate;
	private secondDandRMap = {};
	isBuyer = false;
	validationMessage;
	minDate: Date | undefined;
	selectedBuddySecondDandR;
	isSecondDandR = false;
	isSubmit;

	private secondDandMap: Record<string, unknown> = {};
	filterOptions: Record<string, unknown> = {};
	loading = true;
	totalRecords: number;

	constructor(
		private router: Router,
		private secondDandrService: SecondDandrService,
		private messageService: MessageService,
		private excelService: ExcelService
	) {}

	ngOnInit() {
		this.filterOptions = {};
		this.retriveSecondDandR(this.filterOptions);

		this.columns = [
			{field: 'psa', header: 'PSA#'},
			{field: 'program', header: 'Lead Program'},
			{field: 'commodity', header: 'Material Group'},
			{field: 'commodityName', header: 'Material Group Description'},
			{field: 'seconddandr', header: 'Second D&R'},
			{field: 'buddySeconddandr', header: 'Updated Second D&R'},
			{field: 'fromDate', header: 'From Date'},
			{field: 'toDate', header: 'To Date'},
			{field: 'updateBy', header: 'Updated By'},
			{field: 'updateDate', header: 'Updated Date'},
			{field: 'status', header: 'Reason'}
		];
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const prevMonth = month === 0 ? 11 : month - 1;
		const prevYear = prevMonth === 11 ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);
		this.breadCrumItems = [
			{
				label: 'Buyer Home Page',
				url: '#/buyer-supplier/buyer',
				target: '_self'
			},
			{
				label: 'Update Second D&R',
				url: '#/second-dandr',
				target: '_self'
			}
		];
	}

	onFilter(event: any): void {
		if (event.filteredValue) {
			this.secondDandRExcel = event.filteredValue;
		}
	}

	onRowSelect(data) {
		this.router.navigate(['create-second-dandr/edit/' + data.id]);
	}

	retriveSecondDandR(filterOptions, event?: LazyLoadEvent) {
		let page = 0;
		let size = 10;
		let sortColumn = '';
		let sortOrder = 0;

		if (event) {
			page = event.first / event.rows;
			size = event.rows;
			sortColumn = event.sortField;
			sortOrder = event.sortOrder;
		}

		if (event && event.filters) {
			filterOptions = {
				psa: event.filters.psa ? event.filters.psa.value : null,
				program: event.filters.program
					? event.filters.program.value
					: null,
				commodity: event.filters.commodity
					? event.filters.commodity.value
					: null,
				commodityName: event.filters.commodityName
					? event.filters.commodityName.value
					: null,
				seconddandr: event.filters.seconddandr
					? event.filters.seconddandr.value
					: null,
				buddySeconddandr: event.filters.buddySeconddandr
					? event.filters.buddySeconddandr.value
					: null,
				fromDate: event.filters.fromDate
					? event.filters.fromDate.value
					: null,
				toDate: event.filters.toDate
					? event.filters.toDate.value
					: null,
				updateBy: event.filters.updateBy
					? event.filters.updateBy.value
					: null,
				updateDate: event.filters.updateDate
					? event.filters.updateDate.value
					: null,
				status: event.filters.status ? event.filters.status.value : null
			};
		}

		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer')
		) {
			userId = 'eDCM_IT_ADMIN';
		}

		const isBuyer = apsRoles.includes('Buyer');
		if (isBuyer) {
			userId = sessionStorage.getItem('userId');
		}
		this.secondDandrService
			.findAll(
				userId,
				isBuyer,
				page,
				size,
				sortColumn,
				sortOrder,
				filterOptions
			)
			.subscribe((response) => {
				this.loading = false;
				this.totalRecords = response.totalRecordsAll;
				this.secondDandRs = response.all;
				this.secondDandRExcel = response.exportRecords;
			});
	}

	nullCheck(date) {
		return !date && (date === null || date === 'null');
	}

	exportAsXLSX() {
		const secondDandRExports = [];

		this.secondDandRExcel.forEach((secondDandR) => {
			const secondDandRExport = {};
			secondDandRExport['PSA#'] = secondDandR.psa;
			secondDandRExport['Lead Program'] = secondDandR.program;
			secondDandRExport['Material Group'] = secondDandR.commodity;
			secondDandRExport['Material Group Description'] =
				secondDandR.commodityName;
			secondDandRExport['SecondDandR'] = secondDandR.seconddandr;
			secondDandRExport['Updated SecondDandRs'] =
				secondDandR.buddySeconddandr;
			secondDandRExport['From Date'] = secondDandR.fromDate
				? moment(secondDandR.fromDate).format('MM/DD/YYYY HH:MM')
				: secondDandR.fromDate;
			secondDandRExport['To Date'] = secondDandR.toDate
				? moment(secondDandR.toDate).format('MM/DD/YYYY HH:MM')
				: secondDandR.toDate;
			secondDandRExport['Updated By'] = secondDandR.updateBy;
			secondDandRExport['Updated Date'] = secondDandR.updateDate
				? moment(secondDandR.updateDate).format('MM/DD/YYYY HH:MM')
				: secondDandR.updateDate;
			secondDandRExport['Reason'] = secondDandR.status;

			secondDandRExports.push(secondDandRExport);
		});

		this.excelService.exportAsExcelFile(secondDandRExports, 'secondDandRs');
	}

	handleResponseUpdateFailure() {
		this.secondDandrService
			.bulkUpdatesecondDandRs(this.secondDandRMap)
			.subscribe((response) => {
				this.dtSelectedRows = [];
				if (response) {
					this.retriveSecondDandR(this.filterOptions);
					this.messageService.add({
						severity: 'success',
						summary: 'Success',
						detail: 'secondDandRs Contact information saved successfully for all selected secondDandRs. Please allow at least 15 minutes for the changes to take effect.'
					});
				}
			});
	}

	onBulkUpdate() {
		if (!this.dtSelectedRows || this.dtSelectedRows.length === 0) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'Please select secondDandR Contact from table you want to update.'
			});

			return;
		}

		if (this.isMoving === 'Moving') {
			this.toDate = null;
		}

		//this.buddyBuyerMap['isMoving'] = this.isMoving;
		this.secondDandRMap['fromDate'] = this.fromDate;
		this.secondDandRMap['toDate'] = this.toDate;
		this.secondDandRMap['buddySeconddandr'] = this.seconddandr;

		const ids = [];
		for (const dtSelectedRow of this.dtSelectedRows) {
			const id = {
				id: dtSelectedRow.id,
				psa: dtSelectedRow.psa,
				seconddandr: dtSelectedRow.seconddandr
			};
			ids.push(id);
		}

		this.secondDandRMap['ids'] = ids;
		if (!this.isMandatoryField()) {
			this.validationMessage = 'CDSID is required';
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}
		if (!this.seconddandr) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to provide secondDandR Contact for Bulk update.'
			});
		} else if (
			this.isMoving === 'Vacation' &&
			this.nullCheck(this.secondDandRMap['toDate'])
		) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to set both From and To date for secondDandR Contact.'
			});
		} else if (
			this.isMoving === 'Moving' &&
			this.nullCheck(this.secondDandRMap['fromDate'])
		) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to set From date for secondDandR Contact.'
			});
		} else {
			this.secondDandRMap['buddySeconddandr'] = this.seconddandr;
			this.secondDandRMap['updatedBy'] = sessionStorage.getItem('userId');
			this.secondDandrService
				.validateSecondDandR(this.secondDandRMap)
				.subscribe((responseUpdate) => {
					if (responseUpdate.status === 'Failure') {
						this.handleResponseUpdateFailure();
					}
				});
		}
	}
	isMandatoryField() {
		if (this.isMoving === 'Moving') {
			const mandatory = this.fromDate && this.seconddandr;
			this.isSubmit = !mandatory;

			return mandatory;
		} else if (this.isMoving === 'Vacation') {
			const mandatory = this.toDate && this.fromDate && this.seconddandr;
			this.isSubmit = !mandatory;

			return mandatory;
		}
	}
	validationCheck() {
		this.secondDandRMap['buddySeconddandr'] = this.seconddandr;
		this.secondDandRMap['updatedBy'] = sessionStorage.getItem('userId');
		this.secondDandrService
			.validateSecondDandR(this.secondDandRMap)
			.subscribe((response) => {
				if (response.status === 'Success') {
					this.isSecondDandR = true;
					this.validationMessage = response.validationMessage;
				} else {
					this.isSecondDandR = false;
				}
			});
	}
	restrictSpecialCharacters(event: KeyboardEvent) {
		const allowedKeys = [
			'Backspace',
			'Delete',
			'ArrowLeft',
			'ArrowRight',
			'Tab'
		];
		if (
			!allowedKeys.includes(event.key) &&
			!/[a-zA-Z0-9\s]/.test(event.key)
		) {
			event.preventDefault();
		}
	}
}

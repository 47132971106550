// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep   .p-datatable .p-datatable-thead > tr > th {
	text-align: center;
	padding: 0.571em 0.35em;
	border: 0.7px solid #A7A7A7;
	font-weight: 600;
	font-size: 13px;
	color: #fff;
	background: #00095b;
	transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

:host ::ng-deep   .p-datatable .p-datatable-tbody > tr > td {
	font-size: 13px;
	border: 1px solid rgba(0, 0, 0, 0.08);
	padding: 0.571em 0.35em;
}
.col-9 {
	flex: 0 0 auto;
	padding: 0.5rem;
	width: 75%;
	margin-left: -65em;
}

 .grid{
	margin-top: -0.1rem;
}

.p-table-with-100 {
	width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/manual-rfi-request/manual-rfi-request.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,uBAAuB;CACvB,2BAA2B;CAC3B,gBAAgB;CAChB,eAAe;CACf,WAAW;CACX,mBAAmB;CACnB,qEAAqE;AACtE;;AAEA;CACC,eAAe;CACf,qCAAqC;CACrC,uBAAuB;AACxB;AACA;CACC,cAAc;CACd,eAAe;CACf,UAAU;CACV,kBAAkB;AACnB;;CAEC;CACA,mBAAmB;AACpB;;AAEA;CACC,WAAW;AACZ","sourcesContent":[":host ::ng-deep   .p-datatable .p-datatable-thead > tr > th {\n\ttext-align: center;\n\tpadding: 0.571em 0.35em;\n\tborder: 0.7px solid #A7A7A7;\n\tfont-weight: 600;\n\tfont-size: 13px;\n\tcolor: #fff;\n\tbackground: #00095b;\n\ttransition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;\n}\n\n:host ::ng-deep   .p-datatable .p-datatable-tbody > tr > td {\n\tfont-size: 13px;\n\tborder: 1px solid rgba(0, 0, 0, 0.08);\n\tpadding: 0.571em 0.35em;\n}\n.col-9 {\n\tflex: 0 0 auto;\n\tpadding: 0.5rem;\n\twidth: 75%;\n\tmargin-left: -65em;\n}\n\n .grid{\n\tmargin-top: -0.1rem;\n}\n\n.p-table-with-100 {\n\twidth: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
